@property --green {
  syntax: "<color>";
  inherits: false;
  initial-value: #2dce89;
}

@property --white {
  syntax: "<color>";
  inherits: false;
  initial-value: #ffffff;
}

@property --charcoal {
  syntax: "<color>";
  inherits: false;
  initial-value: #121212;
}

@property --blue-charcoal {
  syntax: "<color>";
  inherits: false;
  initial-value: #27293d;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.dp01 {
  background-color: hsla(0, 100%, 100%, 0.05);
  box-shadow:
		/* offset-x | offset-y | blur-radius | spread-radius | color */
		0px 1px 1px 0px hsla(0,0%,0%,0.14), 
		0px 2px 1px -1px hsla(0,0%,0%,0.12), 
		0px 1px 3px 0px hsla(0,0%,0%,0.2);
    color: rgba(255, 255, 255, 0.87);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--white);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.main-panel { 
  width: 100vw;
  height: 100vh;
}

html {
  height: 100vh;
  background-color: var(--charcoal);
}

#root {
  background-color: var(--charcoal);
  padding-bottom: 50px;
}

.background {
  background-color: var(--charcoal);
}

.card-background {
  background-color: var(--blue-charcoal);
}

.header-item {
  text-decoration: none;
  color: var(--white);
  display: inline-block;
  padding: 5px 10px;
  margin: 10px 5px;
  font-size: 1.1em;
  font-weight: 600;
}

.header-item.active {
  border-bottom: 4px solid var(--green);
}

.white {
  color: var(--white);
}

a.disabled {
  pointer-events: none;
  cursor: default;
  background-color: lightgray;
}

.home-section {
  width: 50%;
  margin-left: 25%;
  margin-right: 25%;
}

.game-card {
  padding: 10px;
  margin: 15px;
  display: block;
  border-radius: 2px;
}

.game-card-row {
  padding: 5px;
  font-size: 0.8em;
}

.game-card-info div {
  font-size: 0.6em;
}

.col {
  display: inline-block;
}

.col-one {
  width: 25%;
}

.col-two {
  width: 10%;
  text-align: center;
}

.col-three {
  width: 10%;
  text-align: center;
}

.col-four {
  width: 10%;
  text-align: center;
}

.col-one-sm {
  width: 50%;
}

.col-two-sm {
  width: 25%;
  text-align: center;
}

.col-three-sm {
  width: 25%;
  text-align: center;
}

.col-four-sm {
  width: 25%;
  text-align: center;
}

.col-last {
  width: 35%;
}

.warm {
  background-color: red;
  color: white;
}


.inning {
  display: inline-block;
}